import api from '../api';

export const uploadSingleFileService = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await api.post('/api/files/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const uploadMultipleFilesService = async (files) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    try {
        const response = await api.post('/api/files/upload-multiple', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};
