import axios from 'axios';

const api = axios.create({
    // baseURL: "http://localhost:7000",
    baseURL: "https://ichs.neovatus.com",
    // headers: {
    //     'Content-Type': 'application/json',
    // },
    withCredentials: true,
});

export default api;
