import React from 'react';
import { Navigate } from 'react-router-dom'; // Assuming you're using React Router
import MainLayout from '../Layout';
import Cookies from 'js-cookie';

function PrivateRoutes({ children }) {
    const user = Cookies.get('user');
    if (!user) {
        return <Navigate to="/login" />;
    }
    return <MainLayout>{children}</MainLayout>;
}

export default PrivateRoutes;
