import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { uploadSingleFileService, uploadMultipleFilesService } from './fileService';

const initialState = {
    files: [],
    isUploading: false,
    error: null,
};

export const uploadSingleFile = createAsyncThunk('files/uploadSingle', async (file, { rejectWithValue }) => {
    try {
        const response = await uploadSingleFileService(file);
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const uploadMultipleFiles = createAsyncThunk('files/uploadMultiple', async (files, { rejectWithValue }) => {
    try {
        const response = await uploadMultipleFilesService(files);
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(uploadSingleFile.pending, (state) => {
                state.isUploading = true;
            })
            .addCase(uploadSingleFile.fulfilled, (state, action) => {
                state.isUploading = false;
                state.files.push(action.payload);
            })
            .addCase(uploadSingleFile.rejected, (state, action) => {
                state.isUploading = false;
                state.error = action.payload;
            })
            .addCase(uploadMultipleFiles.pending, (state) => {
                state.isUploading = true;
            })
            .addCase(uploadMultipleFiles.fulfilled, (state, action) => {
                state.isUploading = false;
                state.files.push(...action.payload);
            })
            .addCase(uploadMultipleFiles.rejected, (state, action) => {
                state.isUploading = false;
                state.error = action.payload;
            });
    },
});
export const selectFiles = (state) => state.fileSlice.files;
export const selectIsUploading = (state) => state.fileSlice.isUploading;
export const selectFileError = (state) => state.fileSlice.error;

export default fileSlice.reducer;
