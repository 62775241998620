import { Button, Card, Col, Form, Input, Row, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function User() {
    const Navigate = useNavigate()
    const [form] = useForm()
    const handleFinish = (values) => {
        console.log('values', values)
    }
    return (
        <Card title="Add New User" extra={<Button type='link' onClick={() => Navigate('/user-list')} >User List</Button>}>
            <Form layout="vertical" form={form} onFinish={handleFinish}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Name" name="firstName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email is required' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Phone" name="phone">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Company" name="company">
                            <Select showSearch allowClear>
                                <Select.Option value="ICHS" >ICHS</Select.Option>
                                <Select.Option value="NEOVATUS">NEOVATUS</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Gender" name="gender">
                            <Select>
                                <Select.Option value="male">Male</Select.Option>
                                <Select.Option value="female">Female</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Role" name="role">
                            <Select showSearch allowClear placement={"bottomRight"}>
                                <Select.Option value="ADMINISTRATOR">ADMINISTRATOR</Select.Option>
                                <Select.Option value="SUBADMIN">SUBADMIN</Select.Option>
                                <Select.Option value="HR">HR</Select.Option>
                                <Select.Option value="OWNER">OWNER</Select.Option>
                                <Select.Option value="WITNESS">WITNESS</Select.Option>
                                <Select.Option value="ACCOUNTANT">ACCOUNTANT</Select.Option>
                                <Select.Option value="NOMINEE">NOMINEE</Select.Option>
                                <Select.Option value="AGENT">AGENT</Select.Option>
                                <Select.Option value="CUSTOMER">CUSTOMER</Select.Option>
                                <Select.Option value="USER">USER</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Username is required' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Password is required' }]}>
                            <Input.Password />
                        </Form.Item>
                    </Col>

                    {/* <Col span={8}>
                        <Form.Item label="Attached File (Optional)" name="attachment">
                            <Upload accept=".png">
                                <Button block>Upload File</Button>
                            </Upload>
                        </Form.Item>
                    </Col> */}
                    <Col span={24}>
                        <Button type="primary" htmlType="submit">
                            Create User
                        </Button>
                    </Col>
                </Row>
            </Form>

        </Card>
    )
}

export default User