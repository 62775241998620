import { Button, Card, Col, Form, Input, notification, Row, Select, Upload } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchCompanies, selectCompanies } from '../app/Service/companySlice'
import { uploadSingleFile } from '../app/Service/fileSlice'
import { addUser } from '../app/Service/userSlice'

function Employee() {
  const Navigate = useNavigate()
  const [file, setFile] = useState()
  const [form] = useForm()
  const handleFinish = async (values) => {
    // Assuming 'file' is already set and available
    values.image = file;

    try {
      // Dispatch the action to add user
      const res = await dispatch(addUser(values));

      // Check if the request status is fulfilled (successful)
      if (res.meta.requestStatus === 'fulfilled') {
        notification.success({
          message: 'Success',
          description: 'User has been successfully added.',
        });
      } else if (res.meta.requestStatus === 'rejected') {
        notification.error({
          message: 'Error',
          description: 'There was an error adding the user.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an unexpected error.',
      });
    }
  };
  const companies = useSelector(selectCompanies)
  const dispatch = useDispatch()
  useEffect(() => {
    // file
    dispatch(fetchCompanies({}))
  }, [])
  const handelUploadFile = async ({ file }) => {
    try {

      const res = await dispatch(uploadSingleFile(file));

      if (res.meta.requestStatus === "fulfilled") {
        const file = res?.payload.file;
        setFile(file)
        notification.success({
          message: 'File Uploaded Successfully',
          description: 'The file was uploaded and companies imported successfully.',
          placement: 'topRight',
        });
      } else {
        notification.error({
          message: 'File Upload Failed',
          description: 'There was an error uploading the file. Please try again.',
          placement: 'topRight',
        });
      }


    } catch (error) {
      notification.error({
        message: 'File Upload Failed',
        description: 'There was an error uploading the file. Please try again.',
        placement: 'topRight',
      });

      console.error('Error handling file upload:', error);
    }
  };
  return (
    <Card title="Add New Employee" extra={<Button type='link' onClick={() => Navigate('/employee-list')} >Employee List</Button>}>
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Username" name="name">
              <Input />
            </Form.Item>

          </Col>
          <Col span={8}>

            <Form.Item label="Email" name="user_email" rules={[{ required: true, message: 'Email is required' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>

            <Form.Item label="First Name" name="firstName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>

            <Form.Item label="Last Name" name="lastName">
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Phone" name="phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Company" name="company_id">
              <Select showSearch allowClear options={
                companies ? companies?.companies?.map((com) => ({
                  label: com?.companyName,
                  value: com?._id
                })) : []
              }
              >

              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>

            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Gender" name="gender">
              <Select>
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Password is required' }]}>
              <Input.Password />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Role" name="role">
              <Select showSearch allowClear placement={"bottomRight"}>
                <Select.Option value="ADMINISTRATOR">ADMINISTRATOR</Select.Option>
                <Select.Option value="SUBADMIN">SUBADMIN</Select.Option>
                <Select.Option value="HR">HR</Select.Option>
                {/* <Select.Option value="OWNER">OWNER</Select.Option> */}
                {/* <Select.Option value="WITNESS">WITNESS</Select.Option> */}
                <Select.Option value="ACCOUNTANT">ACCOUNTANT</Select.Option>
                {/* <Select.Option value="NOMINEE">NOMINEE</Select.Option> */}
                <Select.Option value="AGENT">AGENT</Select.Option>
                <Select.Option value="EMPLOYEE">EMPLOYEE</Select.Option>
                <Select.Option value="USER">USER</Select.Option>
                {/* <Select.Option value="CUSTOMER">CUSTOMER</Select.Option> */}
              </Select>
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item label="Attached File (Optional)" name="attachment">
              <Upload showUploadList={false} accept=".png,jpe,jpeg" beforeUpload={() => false} onChange={handelUploadFile}>
                <Button block>Upload File</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: "10px" }}>
            <Button type="primary" htmlType="submit">
              Create User
            </Button>
          </Col>
        </Row>
      </Form>

    </Card >
  )
}

export default Employee