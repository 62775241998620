

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './Client/Layout'
import Login from './Client/Pages/Login';
import Dashboard from './Client/Pages/Dashboard'
import { ConfigProvider } from 'antd';
import User from './Client/Pages/User'
import File from './Client/Pages/File'
import Company from './Client/Pages/Company'
import Tenants from './Client/Pages/Tenants'
import Bank from './Client/Pages/Bank'
import FileList from './Client/Pages/FileList'
import BankList from './Client/Pages/BankList'
import PaymentVocher from './Client/Pages/PaymentVocher'
import PaymentListVoucher from './Client/Pages/PaymentListVoucher'
import PaymentInvoice from './Client/Pages/PaymentInvoice'
import PaymentListInvoice from './Client/Pages/PaymentListInvoice'
import Transaction from './Client/Reports/Transaction';
import AccountBalance from './Client/Reports/AccountBalance';
import Income from './Client/Reports/Income';
import Expense from './Client/Reports/Expense';
import TrialBalance from './Client/Reports/TrialBalance';
import BalanceSheet from './Client/Reports/BalanceSheet';
import Signup from './Client/Pages/Signup';
import PrivateRoutes from './Client/private/privateRoutes';
import UserList from './Client/Pages/UserList';
import { Provider } from 'react-redux';
import { store } from './Client/app/store';
import EmployeeList from './Client/Pages/EmployeeList';
import Employee from './Client/Pages/Employee';
function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={{
        "token": {
          "controlHeight": 38
        },
        "components": {
          "Input": {
            "borderRadius": 2
          },
          "Menu": {
            "activeBarBorderWidth": 0
          },
          "Select": {
            "borderRadius": 2,
            "borderRadiusLG": 2
          },
          "Button": {
            "borderRadius": 2
          },
          "Table": {
            "fontSize": 10,
            "padding": 8,
            "headerBorderRadius": 0,
            "borderRadius": 0,
            "fontWeightStrong": 60
          },
          "DatePicker": {
            "borderRadius": 2,
            "borderRadiusLG": 2
          }
        }
      }}>
        <BrowserRouter>
          <Routes>
            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<PrivateRoutes />}>
              <Route index element={<Dashboard />} />
              <Route path="user" element={<User />} />
              <Route path="user-list" element={<UserList />} />
              <Route path="company" element={<Company />} />
              <Route path="employee" element={<Employee />} />
              <Route path="employee-list" element={<EmployeeList />} />
              <Route path="branchs" element={<Tenants />} />
              <Route path="Bank" element={<Bank />} />
              <Route path="bank-list" element={<BankList />} />
              <Route path="payment-Voucher" element={<PaymentVocher />} />
              <Route path="payment-list-Voucher" element={<PaymentListVoucher />} />
              <Route path="payment-Invoice" element={<PaymentInvoice />} />
              <Route path="payment-list-Invoice" element={<PaymentListInvoice />} />
              <Route path="file" element={<File />} />
              <Route path="file-list" element={<FileList />} />
              <Route path="Transaction" element={<Transaction />} />
              <Route path="Account Balance" element={<AccountBalance />} />
              <Route path="Income" element={<Income />} />
              <Route path="Expense" element={<Expense />} />
              <Route path="Trial Balance" element={<TrialBalance />} />
              <Route path="Balance Sheet" element={<BalanceSheet />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}
export default App;