import { configureStore } from "@reduxjs/toolkit";
import companySlice from './Service/companySlice'
import fileSlice from './Service/fileSlice'
import userSlice from './Service/userSlice'

export const store = configureStore({
    reducer: {
        companySlice,
        fileSlice,
        userSlice
    },
});
