import React, { useState } from 'react';
import { notification } from 'antd';
import './Signup.css';
import { useNavigate } from 'react-router-dom';
import api from '../app/api';
const Signup = () => {
    const Navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        name: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
        name: '',
    });

    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');

    const spanCount = 282;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: '' })); // Clear errors as user types
        setErr(''); // Clear global errors as user types
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const newErrors = {};

        if (!formData.name) newErrors.name = 'name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Invalid email address';
        if (!formData.password) newErrors.password = 'Password is required';
        else if (formData.password.length < 6) newErrors.password = 'Password must be at least 6 characters';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setLoading(false);
            notification.error({
                message: 'Signup Failed',
                description: 'Please fix the errors in the form.',
            });
            return;
        }

        try {
            setLoading(true);

            const response = await api.post("/api/auth/register", formData);

            if (response.status === 201) {
                setLoading(false);
                Navigate("/login");
                notification.success({
                    message: "Signup Successful",
                    description: "Your account has been created successfully.",
                });
            } else {
                console.log("data", response.data);
                setErr(response.data.error || "Signup failed. Please try again.");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error:", error);
            setErr(error.response?.data?.error || "An error occurred. Please try again later.");
            setLoading(false);
        }
    };

    return (
        <div className='main signup'>
            <section>
                {Array.from({ length: spanCount }).map((_, index) => (
                    <span key={index}></span>
                ))}
                <div className="signin">
                    <div className="content">
                        <h2>Sign Up</h2>

                        <form className="form" onSubmit={handleSubmit}>
                            {err && <div style={{ height: "0px", width: "100%", color: "red" }}>{err}</div>}

                            <div className="inputBox">
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                <i>name</i>
                                {errors.name && <span className="" style={{ height: "0px", width: "100%", color: "red" }}>{errors.name}</span>}
                            </div>
                            <div className="inputBox">
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <i>Email</i>
                                {errors.email && <span className="" style={{ height: "0px", width: "100%", color: "red" }}>{errors.email}</span>}
                            </div>
                            <div className="inputBox">
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                                <i>Password</i>
                                {errors.password && <span className="" style={{ height: "0px", width: "100%", color: "red" }}>{errors.password}</span>}
                            </div>
                            <div className="links">
                                <a href="#">Forgot Password</a>
                                <a onClick={() => Navigate('/login')}>Login</a>
                            </div>
                            <div className="inputBox">
                                <input type="submit" disabled={loading} value={loading ? 'Loading...' : 'Signup'} />

                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Signup;
