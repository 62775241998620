import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Dropdown, Menu, Pagination, Card, Flex, Modal, Form, Space, Upload, notification, Typography, Row, Col, Tooltip, Select } from 'antd';
import { FilterOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined, ImportOutlined, PrinterFilled } from '@ant-design/icons';
import {
    deleteUser, exportuser, importuser, selectError, selectLoading, addCompany, updateUser, handlePrint,
    fetchusers,
    selectUsers,
    addUser
} from '../app/Service/userSlice';
import {
    fetchCompanies,
    selectCompanies
} from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux'
import { uploadSingleFile } from '../app/Service/fileSlice';
import { userService } from '../app/Service/userService';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom'
const EmployeeList = () => {
    const [valuestext, setvaluestext] = useState('')
    const [Company_id, setcompany_id] = useState('')

    const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [form] = useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Users = useSelector(selectUsers);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const [Users_, setUser_] = useState([]);
    const [limit, setLimit] = useState(20);
    const [start, setStart] = useState(0);
    const company = useSelector(selectCompanies)
    const [btnloading, setbtnloading] = useState(false)
    const handleDelete = (id) => {
        Modal.confirm({
            title: "Are you sure?",
            content: "This action cannot be undone.",
            okText: "Yes, Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk: async () => {
                await dispatch(deleteUser(id))
                dispatch(fetchusers({}));

            },
        });
    };
    const columns = [
        {
            title: '#',
            dataIndex: 'rowNumber',
            render: (text, record, index) => index + 1,
            width: 50,
        },
        {
            title: 'Username',
            dataIndex: 'name',
            key: 'companyName',
        },
        {
            title: 'Full Name',
            dataIndex: 'firstName',
            render:(text,data)=>data?.firstName +" - " +data?.lastName
        },
        {
            title: 'User',
            dataIndex: 'user_email',
            key: 'user_email',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Dropdown dropdownRender={() => <Menu>
                    {/* <Menu.Item onClick={() => handleEdit(record)}>Edit</Menu.Item> */}
                    <Menu.Item onClick={() => handleDelete(record?._id)}>Delete</Menu.Item>
                </Menu>}>
                    <Button>Action</Button>
                </Dropdown>
            ),
        },
    ];
    const handelUploadFile = async ({ file }) => {
        try {
            if (Company_id) {

                const res = await dispatch(uploadSingleFile(file));

                if (res.meta.requestStatus === "fulfilled") {
                    const file = res?.payload.file;

                    await dispatch(importuser(file));

                    await dispatch(fetchusers({ id: Company_id }));
                    notification.success({
                        message: 'File Uploaded Successfully',
                        description: 'The file was uploaded and companies imported successfully.',
                        placement: 'topRight',
                    });
                }
            } else {
                notification.error({
                    message: 'Please Select Company',
                    description: 'There was an error uploading the file. Please try again.',
                    placement: 'topRight',
                });
            }

        } catch (error) {
            notification.error({
                message: 'File Upload Failed',
                description: 'There was an error uploading the file. Please try again.',
                placement: 'topRight',
            });

            console.error('Error handling file upload:', error);
        }
    };


    useEffect(() => {
        dispatch(fetchusers({ query: "", limit, start }));
        dispatch(fetchCompanies({}));


    }, []);


    const handleSearch = (e) => {
        setvaluestext(e.target.value)
        dispatch(fetchusers({ query: e.target.value }));
    };

    const handleEdit = (record) => {
        setEditingRecord(record);
        form.setFieldsValue(record);
        setIsCompanyModalVisible(true);
    };

    const handleCompany = async (values) => {
        setbtnloading(true)
        if (editingRecord) {
            const res = await dispatch(updateUser({ id: editingRecord?._id, updatedData: values }));

            if (res.meta.requestStatus === 'rejected') {
                notification.error({
                    message: 'Error',
                    description: res.payload || 'Something went wrong. Please try again.',
                });
                setbtnloading(false)

            } else {
                notification.success({
                    message: 'Success',
                    description: 'Company Updated successfully!',
                });
                setbtnloading(false)

                dispatch(fetchusers({ query: "" }))
            }
        } else {
            const res = await dispatch(addUser(values));
            setbtnloading(false)

            if (res.meta.requestStatus === 'rejected') {
                notification.error({
                    message: 'Error',
                    description: res.payload || 'Something went wrong. Please try again.',
                });
            } else {
                notification.success({
                    message: 'Success',
                    description: 'Company added successfully!',
                });
                dispatch(fetchusers({ query: "" }))
                setbtnloading(false)

                form.resetFields();
            }
            setbtnloading(false)

        }

    };
    const handleScroll = async (e) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        let array1 = (Users && Users.employee) ? Users.employee : []
        let array2 = Users_ ? Users_ : []
        let table = [...array1, ...array2]
        if (bottom && !loading && table.length < Users.totalCount) {
            const data = await userService.getAlluses({ query: valuestext, limit: limit, start: start + limit })
            setStart(start + limit);
            let newdata = [...data?.employee]
            setUser_(newdata)
        }
    };
    let array1 = (Users && Users.employee) ? Users.employee : []
    let array2 = Users_ ? Users_ : []
    let table = [...array1, ...array2]
    return (
        <Card title="Employee List">
            <div>
                <Space style={{ marginBottom: 16 }}>
                    <Button type="primary" onClick={() => navigate('/employee')}>+ Add Employee</Button>

                </Space>
                <Flex justify={"space-between"} align={"center"}>
                    <Input
                        placeholder="Search Employee"
                        onChange={handleSearch}
                        value={valuestext}
                        style={{ width: 500, marginBottom: 16 }}
                    />

                    <div style={{ marginBottom: 16 }}>
                        <Typography.Text style={{ marginRight: "20px" }}>
                            Showing {table?.length} of {Users?.totalCount}
                        </Typography.Text>

                        {/* <Tooltip title="Import">
                            <Upload
                                accept=".xls,.xlsx"
                                onChange={handelUploadFile}
                                showUploadList={false}
                                beforeUpload={() => false}>
                                <Button variant={'outlined'} color={'danger'} icon={<ImportOutlined />} style={{ marginRight: 8 }}></Button>
                            </Upload>
                        </Tooltip> */}
                        <Tooltip title="Export in to Excel"> <Button variant={'outlined'} onClick={() => dispatch(exportuser("excel"))} color={"primary"} icon={<FileExcelOutlined />} style={{ marginRight: 8 }} /></Tooltip>
                        <Tooltip title="Export in to Pdf">
                            <Button variant={'outlined'} onClick={() => dispatch(exportuser("pdf"))} icon={<FilePdfOutlined />} style={{ marginRight: 8, borderColor: "green", color: "green" }} /> </Tooltip>
                        <Tooltip title="Print"><Button variant={'outlined'} onClick={() => dispatch(handlePrint())} style={{ borderColor: "orange", color: "orange" }} icon={<PrinterFilled />} /> </Tooltip>
                    </div>
                </Flex>
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={table}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 300px)' }}
                    onScroll={handleScroll}
                />

                <Modal
                    title={editingRecord ? "Update Company Details" : "Add Company"}
                    open={isCompanyModalVisible}
                    onCancel={() => setIsCompanyModalVisible(false)}
                    footer={null}  // Keep null to avoid default buttons
                    style={{ top: 20 }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleCompany}
                        autoComplete="off"
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item
                                    name="companyName"
                                    label="Company Name"
                                    rules={[{ required: true, message: 'Company Name is required' }]}
                                >
                                    <Input disabled={editingRecord} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="registrationNo"
                                    label="Registration No"
                                    rules={[{ required: true, message: 'Registration No is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                    rules={[{ required: true, message: 'Address is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="user_email"
                                    label="User Email"

                                    rules={[
                                        { required: true, message: 'User Email is required' },
                                        { type: 'email', message: 'Enter a valid email' }
                                    ]}
                                >
                                    <Input disabled={editingRecord} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[{ required: true, message: 'Phone is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                {btnloading ? <Button type="primary" loading={btnloading}>Save</Button> : <Button type="primary" htmlType="submit">Save</Button>}

                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </Card>
    );
};

export default EmployeeList;
